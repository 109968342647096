import React from 'react'
import { faFileAlt, faIndustry } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormGroup from '../../form/form-group'
import FormField, { HookFormWrapper } from '../../form/form-field'
import { useTranslation } from 'gatsby-plugin-react-i18next'

//TODO: i18n

const OrganizationFormFields = ({ control, kveRequired }) => {
  const { t } = useTranslation()
  return (
    <FormGroup title={t('user-pro-form:company_title')} mb={6}>

      <FormField label={t('forms:company.company')}>
        <HookFormWrapper
          name='organization.name'
          control={ control }
          icon={ <FontAwesomeIcon icon={faIndustry} /> }
          placeholder={t('forms:company.company_placeholder')}
          rules={ { required: { value: true, message: t('forms:validations.required_field') } } }
        />
      </FormField>

      <FormField label={t('forms:company.vat')}>
        <HookFormWrapper
          name='organization.vat'
          control={ control }
          icon={ <FontAwesomeIcon icon={faFileAlt} /> }
          placeholder='xxO123456789'
          help={t('forms:company.vat_info')}
          controlProps={{ style: { width: '50%' } }} //TODO: peut-être moyen de gérer ça autrement avec Bulma?
          rules={ { required: { value: true, message: t('forms:validations.required_field') } } }
        />
      </FormField>

      <FormField label={t('forms:company.kve')}>
        <HookFormWrapper
          name='organization.kve'
          control={ control }
          icon={ <FontAwesomeIcon icon={faFileAlt} />}
          placeholder='123456789'
          help={t('forms:company.kve_info')}
          controlProps={{ style: { width: '50%' } }}
          rules={ kveRequired && { required: { value: true, message: t('forms:validations.required_field') } } }
        />
      </FormField>

    </FormGroup>
  ) }
export default OrganizationFormFields
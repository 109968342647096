import React from 'react'
import { Button, Element } from 'react-bulma-components'
import OrganizationFormFields from './organization-fields'
import AddressFormFields from './address-fields'
import UserFormFields from './user-fields'
import { useForm } from 'react-hook-form'
import { useSession, useSessionRequest } from '../../../stores/session'
import { RequestMessage } from '../../../stores/utils'
import { Link, useI18next } from 'gatsby-plugin-react-i18next'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import FormField, { HookFormWrapper } from '../../form/form-field'
import FormGroup from '../../form/form-group'

//TODO: i18n

const UserForm = () => {
  const { t } = useTranslation(['user-pro-form', 'forms'])
  const { control, watch, handleSubmit, setValue } = useForm({
    shouldUseNativeValidation: true
  })
  const [state, actions] = useSession()
  const [request] = useSessionRequest({
    key: 'post /users/register-as-pro'
  })
  const onSubmit = data => {
    const organizationsAddresses = []
    const mainAddress = data.organization.addresses.main
    organizationsAddresses.push({
      ...mainAddress,
      isMainAddress: true
    })
    if (!data.__duplicate.billingAddress) {
      organizationsAddresses.push({
        ...data.organization.addresses.billing,
        isBillingAddress: true
      })
    }
    if (!data.__duplicate.deliveryAddress) {
      organizationsAddresses.push({
        ...data.organization.addresses.delivery,
        isDeliveryAddress: true
      })
    }
    data.organization.addresses = organizationsAddresses
    const organizationContacts = []
    organizationContacts.push({ ...data.user, type: 'main', password: undefined })

    //Hack for hook-form
    if (!data.__duplicate.commercialContact)
      organizationContacts.push({
        ...data.organization.contacts.commercial,
        type: 'commercial'
      })
    if (!data.__duplicate.billingContact)
      organizationContacts.push({
        ...data.organization.contacts.billing,
        type: 'billing'
      })
    if (!data.__duplicate.logisticsContact)
      organizationContacts.push({
        ...data.organization.contacts.logistics,
        type: 'logistics'
      })
    delete data.__duplicate
    data.organization.contacts = organizationContacts

    actions.registerAsPro(data)
  }

  const duplicateBillingAddress = watch('__duplicate.billingAddress', true)
  const duplicateDeliveryAddress = watch('__duplicate.deliveryAddress', true)
  const duplicateCommercialContact = watch('__duplicate.commercialContact', true)
  const duplicateBillingContact = watch('__duplicate.billingContact', true)
  const duplicateLogisticsContact = watch('__duplicate.logisticsContact', true)

  const country = watch('organization.addresses.main.country')

  const [kveRequired, setKveRequired] = React.useState(false)
  React.useEffect(() => {
    if (country === 'NL' && !kveRequired)
      setKveRequired(true)
    else if (kveRequired)
      setKveRequired(false)
  }, [country])

  const { navigate } = useI18next()
  React.useEffect(() => {
    if (request && request.status === 'success') {
      actions.clearRequest({
        key: 'post /users/register-as-pro'
      })
      navigate('/thank-you')
    }
  }, [request])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <OrganizationFormFields
        control={ control }
        kveRequired={ kveRequired }
      />
      <AddressFormFields
        title={t('user-pro-form:addresses.main')}
        formPath='organization.addresses.main'
        control={ control }
      />
      <AddressFormFields
        title={t('user-pro-form:addresses.billing')}
        formPath='organization.addresses.billing'
        duplicatedPath='__duplicate.billingAddress'
        showForm={!duplicateBillingAddress}
        control={ control }
      />
      <AddressFormFields
        title={t('user-pro-form:addresses.delivery')}
        formPath='organization.addresses.delivery'
        duplicatedPath='__duplicate.deliveryAddress'
        showForm={!duplicateDeliveryAddress}
        control={ control }
      />
      <Element renderAs={ 'div' } mb={6} />
      <UserFormFields
        title={t('user-pro-form:main_account')}
        formPath='user'
        noPassword
        control={ control }
      />
      <UserFormFields
        title={t('user-pro-form:contacts.commercial')}
        formPath='organization.contacts.commercial'
        duplicatedPath='__duplicate.commercialContact'
        showForm={!duplicateCommercialContact}
        noPassword
        control={ control }
      />
      <UserFormFields
        title={t('user-pro-form:contacts.billing')}
        formPath='organization.contacts.billing'
        duplicatedPath='__duplicate.billingContact'
        showForm={!duplicateBillingContact}
        noPassword
        control={ control }
      />
      <UserFormFields
        title={t('user-pro-form:contacts.logistics')}
        formPath='organization.contacts.logistics'
        duplicatedPath='__duplicate.logisticsContact'
        showForm={!duplicateLogisticsContact}
        noPassword
        control={ control }
      />

      <FormGroup title={t('forms:rgpd_title')}>

        <FormField label={' '}>
          <HookFormWrapper
            name={ 'rgpd' }
            control={ control }
            label={<span>{t('forms:rgpd_accept_our')} <Element textColor='info' renderAs={Link} to='/rgpd' target={'__blank'}>{t('forms:rgpd_title').toLowerCase()}</Element></span>}
            component='Checkbox'
            defaultValue={false}
            rules={ { required: { value: true, message: t('forms:validations.required_field') } } }
          />
        </FormField>
      </FormGroup>
      <Button>{t('forms:submit')}</Button>
      <Element mt={4}>
        <RequestMessage
          request={ request }
          messages={{
            error: <span>
              {
                request && request.data && request.data.data.message && request.data.data.message === 'Existing organization name' &&
                  <span>
                    <Trans i18nKey='user-pro-form.errors.existing_organization'/>
                  </span>
              }
              {
                request && request.data && request.data.data.message && request.data.data.message === 'Email already in use' &&
                  <span>
                    <Trans i18nKey='user-pro-form.errors.existing_email'/>
                  </span>
              }
              <br />
              {t('forms:errors.contact_us.1')} {t('forms:errors.contact_us.2')} {t('forms:errors.contact_us.3')}
            </span>
          }}
        />
      </Element>
    </form>
  )
}

export default UserForm
import React from 'react'
import { Element, Form, Heading } from 'react-bulma-components'

const FormGroup = ({ title, children, mb, ...rest }) => (
  <Element mb={mb ? mb : 5} {...rest}>
    {
      title &&
        <Form.Field horizontal>
          <Form.Field.Label>
            <Form.Label />
          </Form.Field.Label>
          <Form.Field.Body>
            <Heading size={6} textColor='grey' textTransform='uppercase'>
              {title}
            </Heading>
          </Form.Field.Body>
        </Form.Field>
    }
    {children}
  </Element>
)

export default FormGroup
import React from 'react'
import FormGroup from '../../form/form-group'
import FormField, { HookFormWrapper } from '../../form/form-field'
import { countriesAsSelectioOptions } from '../../../constants/countries'
import { useTranslation } from 'gatsby-plugin-react-i18next'

//TODO: i18n

const AddressFormFields = ({ title, formPath, duplicatedPath, showForm, control }) => {
  const { t } = useTranslation()
  return (
    <FormGroup title={title}>
      { duplicatedPath && //TODO: Composant pour gérer le cas? Checkbox qui affiche un form/component
      //      Du coup, peut-être pas besoin de passer par le state du form pour l'affichage ou nom.
        <FormField label=' '>
          <HookFormWrapper
            name={ duplicatedPath }
            control={ control }
            label={t('forms:address.duplicate')}
            component='Checkbox'
            defaultValue={true}
          />
        </FormField>
      }
      {
        (!duplicatedPath || showForm) &&
          <>
            <FormField label={t('forms:address.country')}>
              <HookFormWrapper
                name={`${formPath}.country`}
                component='Select'
                options={ countriesAsSelectioOptions }
                control={ control }
                rules={ { required: { value: true, message: t('forms:validations.required_field') } } }
              />
            </FormField>
            <FormField label={t('forms:address.street')}>
              <HookFormWrapper
                name={`${formPath}.street`}
                control={ control }
                rules={ { required: { value: true, message: t('forms:validations.required_field') } } }
              />
            </FormField>

            <FormField label={t('forms:address.number_and_box')} bodyFieldProps={ { display: 'flex' }}>
              <>
                <HookFormWrapper
                  name={`${formPath}.streetNumber`}
                  control={ control }
                  rules={ { required: { value: true, message: t('forms:validations.required_field') } } }
                  controlProps={{ style: { width: '5em' } }}
                />
                <HookFormWrapper
                  name={`${formPath}.streetBoxNumber`}
                  control={ control }
                  controlProps={{ style: { width: '5em' }, ml: 3 }}
                />
              </>
            </FormField>

            <FormField label={t('forms:address.zipcode')}>
              <HookFormWrapper
                name={`${formPath}.zip`}
                control={ control }
                controlProps={{ style: { width: '11em' } }}
              />
            </FormField>

            <FormField label={t('forms:address.city')}>
              <HookFormWrapper
                name={`${formPath}.city`}
                control={ control }
              />
            </FormField>
          </>
      }

    </FormGroup>

  )
}

export default AddressFormFields
import React from 'react'
import { faEnvelope, faFax, faPhone, faShieldAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormGroup from '../../form/form-group'
import FormField, { HookFormWrapper } from '../../form/form-field'
import { useTranslation } from 'gatsby-plugin-react-i18next'

//TODO: i18n

const UserFormFields = ({
  title,
  formPath = '',
  duplicatedPath,
  showForm,
  noPassword,
  control,
  defaultValues = {},
  disabled = {}
}) => {
  if (formPath)
    formPath = `${formPath}.`

  console.log(defaultValues, `${formPath}lastName`)

  const { t } = useTranslation()
  return (
    <FormGroup title={title}>

      { duplicatedPath &&
        <FormField label=' '>
          <HookFormWrapper
            name={ duplicatedPath }
            control={ control }
            label={t('forms:contact.duplicate')}
            component='Checkbox'
            defaultValue={true}
          />
        </FormField>
      }

      {
        (!duplicatedPath || showForm) &&
          <>
            <FormField label={t('forms:contact.lastName')}>
              <HookFormWrapper
                name={`${formPath}firstName`}
                control={ control }
                rules={ { required: { value: true, message: t('forms:validations.required_field') } } }
                icon={ <FontAwesomeIcon icon={faUser} /> }
              />
            </FormField>
            <FormField label={t('forms:contact.firstName')}>
              <HookFormWrapper
                name={`${formPath}lastName`}
                control={ control }
                rules={ { required: { value: true, message: t('forms:validations.required_field') } } }
                icon={ <FontAwesomeIcon icon={faUser} /> }
              />
            </FormField>

            <FormField label={t('forms:contact.email')}>
              <HookFormWrapper
                name={`${formPath}email`}
                control={ control }
                rules={ { required: { value: true, message: t('forms:validations.required_field') } } }
                type='email'
                icon={ <FontAwesomeIcon icon={faEnvelope} /> }
                defaultValue={defaultValues.email}
                disabled={disabled.email}
              />
            </FormField>

            <FormField label={t('forms:contact.phone')}>
              <HookFormWrapper
                name={`${formPath}phone`}
                control={ control }
                controlProps={{ style: { width: '50%' } }}
                icon={ <FontAwesomeIcon icon={faPhone} /> }
              />
            </FormField>

            <FormField label={t('forms:contact.fax')}>
              <HookFormWrapper
                name={`${formPath}fax`}
                control={ control }
                controlProps={{ style: { width: '50%' } }}
                icon={ <FontAwesomeIcon icon={faFax} /> }
              />
            </FormField>

            {
              !noPassword &&
                <FormField label={t('forms:contact.password')}>
                  <HookFormWrapper
                    name={`${formPath}password`}
                    control={ control }
                    icon={ <FontAwesomeIcon icon={faShieldAlt} /> }
                    type='password'
                    rules={ {
                      required: { value: true, message: t('forms:validations.required_field') },
                      minLength: { value: 6, message: t('forms:validations.password_length') }
                    } }
                  />
                </FormField>
            }
          </>
      }
    </FormGroup>
  )
}
export default UserFormFields
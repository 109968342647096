import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { Columns, Container, Element, Heading, Section } from 'react-bulma-components'
import UserForm from '../components/users/form/user-form'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'

const SignUpAsProPage = () => {
  const { t } = useTranslation(['user-pro-form'])
  return (
    <Layout>

      <Section size='medium'>
        <Container>
          <Columns centered gap={8} className='has-border-right' breakpoint='desktop'>
            <Columns.Column narrow size={6}>
              <Heading size={5} textColor='dark' mb={6}>
                {t('user-pro-form:title')}
              </Heading>
              { /*<Element renderAs='p' mb={6}>
                <Trans i18nKey='user-pro-form.description'/>
  </Element>*/ }
              <UserForm />
            </Columns.Column>
          </Columns>
        </Container>
      </Section >
    </Layout >
  )
}

export default SignUpAsProPage

export const query = graphql`
  query($language: String!) {
          locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
          node {
          ns
          data
          language
        }
      }
    }
  }
`